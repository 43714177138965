<script setup>
const props = defineProps({ blok: Object, singleItem: Boolean });
const singleItem = computed(() => props.singleItem || props.blok?.single_item);
</script>

<template>
  <div
    v-editable="blok"
    :class="{
      'features-small-content-grid': !singleItem,
    }"
  >
    <StoryblokComponent
      v-for="(item, index) in blok?.blocks"
      :key="index"
      :blok="item"
    />
    <slot />
  </div>
</template>
